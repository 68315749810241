const items = [
  {
    icon: "product",
    title: "Productos",
    to: "/admin/products",
    mobile: true,
    permission: 'read_items',
  },
  {
    icon: "documento",
    title: "Ordenes",
    to: "/admin/orders",
    mobile: true,
    permission: 'read_orders'
  },
  {
    icon: "stock",
    title: "Stock",
    to: "/admin/stock",
    mobile: true,
    permission: 'read_stock',
  },
  {
    icon: "tags",
    title: "Tags",
    to: "/admin/more-options-tags",
    mobile: true,
    permission: 'read_config_tag',
  },
  {
    icon: "dispositivos2",
    title: "Dispositivos",
    to: "/admin/devices",
    mobile: true,
    permission: 'read_devices',
  },
  {
        icon: "arc",
        title: "Arco",
        to: "/admin/arc-activity",
        mobile: true,
        permission: 'read_devices',
  },
  {
    icon: "presis",
    title: "Alertas",
    to: "/admin/presisactivity",
    mobile: true,
    permission: 'read_alerts',
  },
  {
    icon: "reportes",
    title: "Reportes",
    to: "/admin/reportes",
    mobile: true,
    permission: 'reports',
  },
  {
    icon: "configuraciones",
    title: "Mas opciones",
    to: "/admin/more-options",
    mobile: true,
    permission: 'all',
  },
  {
    icon: "account",
    title: "Mi cuenta",
    to: "/admin/account",
    permission: 'all'
  },
];

export default items;
